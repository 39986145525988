
import imgPricing1 from 'assets/images/app/pricing/plane1.png';
import imgPricing2 from 'assets/images/app/pricing/plane2.png';
import imgPricing3 from 'assets/images/app/pricing/plane3.png';

const data = {
    pricings: [
        {
            thumb: imgPricing1,
            name: 'Starter',
            price: '$6.99 / user / month',
            priceAnnual: '$69 / user / annum',
            // description: 'It is only $3 per user for up to 20 users.',
            link: '#',
            btnText: 'Get Package',
            services: [
                {
                    content: '1x Basic Notification',
                    className: 'on'
                },
                {
                    content: '1x Any MyCorp Module',
                    className: 'on'
                },
                {
                    content: '8 x 5 support',
                    className: 'on'
                }
            ]
        }, {
            thumb: imgPricing2,
            name: 'Enthusiast',
            price: '$14.99 / user / month',
            priceAnnual: '$149 / user / annum',
            // description: 'It is only $5 per user for up to 50 users.',
            link: '#',
            btnText: 'Get Package',
            services: [
                {
                    content: '1x Basic Notification',
                    className: 'on'
                },
                {
                    content: '3x Any MyCorp Modules',
                    className: 'on'
                },
                {
                    content: '8 x 5 support',
                    className: 'on'
                }
            ]
        },
        {
            thumb: imgPricing3,
            name: 'Enterprise',
            price: '< contact us >',
            // description: 'It is only $10 per user for up to 100 users.',
            link: '#',
            btnText: 'Get Package',
            services: [
                {
                    content: 'More than 3x MyCorp Modules',
                    className: 'on'
                },
                {
                    content: 'Customisation',
                    className: 'on'
                },
                {
                    content: 'On-premise',
                    className: 'on'
                },
                {
                    content: 'Over 100 users',
                    className: 'on'
                },
                {
                    content: '24 x 7 support',
                    className: 'on'
                }
            ]
        }

    ]

};
export default data;
