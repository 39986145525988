import React from "react";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import VintageBox from "reusecore/VintageBox";
import particle1 from "assets/images/app/particle/14.png";
import particle2 from "assets/images/app/particle/15.png";
import { Link } from "gatsby"

import data from "assets/data/pricing";

import PricingSectionWrapper from "./pricingSection.style";

const Pricing = (props) => {
    console.log("props: ", props)
    return (
        <PricingSectionWrapper id="pricing">
            <img className="section__particle one" src={particle1} alt="img" />
            <img className="section__particle two" src={particle2} alt="img" />
            <Container>
                <SectionTitle UniWidth="65%">
                    <h2>
                        <span>Flexible Pricing Plans</span>
                    </h2>
                </SectionTitle>
                <Row>
                    {data.pricings.map((pricing, index) => (
                        <Col xs={12} sm={6} lg={4} key={index}>
                            <div className="pricing-block">
                                <div className="thumb-block">
                                    <img src={pricing.thumb} alt="appion app landing" />
                                </div>
                                <div className="price-block">
                                    <h3>{pricing.name}</h3>
                                    <p>{pricing.price}</p>
                                    <p>{pricing.priceAnnual}</p>
                                </div>
                                <div className="details-block">
                                    {pricing.services.map((service, index) => (
                                        <span className={service.className} key={index}>
                                            {service.className === "on" ? (
                                                <IoMdCheckmark />
                                            ) : (
                                                    <IoMdClose />
                                                )}
                                            {service.content}
                                        </span>
                                    ))}
                                </div>
                                {/* <Button className="pricing-btn">{pricing.btnText}</Button> */}
                            </div>
                        </Col>
                    ))}
                </Row>
                <div UniWidth="65%">
                    <br />
                    <VintageBox right={true} vintageOne={true}>
                        <Link to="/get-no-obligation-quote"   >
                            <Button className="banner-btn one">
                                Get a NO Obligation Quote
                            </Button>
                        </Link>
                        <Link to="/request-for-trial"  >
                            <Button className="banner-btn two" >
                                Request for No Obligation 1 Month Trial
                            </Button>
                        </Link>
                    </VintageBox>
                </div>
            </Container>
        </PricingSectionWrapper>
    );
};

export default Pricing;
